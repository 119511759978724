/* eslint-disable */
import YAML from 'yaml';
import $ from 'jquery';
import {CurrentPage} from "@/models/CurrentPage";
import {UserSingleton} from "@/models/UserSingleton";

export default {
  data() {
    return {
      token: false,
      activeRequests: [],
      currentPage: CurrentPage(this),
      ukey: null, // process.env.VUE_APP_UKEY,
      gkey: null, // process.env.VUE_APP_GKEY,
      base_url: null, // process.env.VUE_APP_BASE_URL,
      configServerUrl: 'https://s.slidelab.io',
      storageUrl: null, // process.env.VUE_APP_STORAGE_URL
    };
  },
  created() {
    this.ukey = process.env.VUE_APP_UKEY;
    this.gkey = process.env.VUE_APP_GKEY;
    this.base_url = process.env.VUE_APP_BASE_URL;
    this.storageUrl = process.env.VUE_APP_STORAGE_URL;



    // const user = UserSingleton.getInstance();
    //
    // if (user) {
    //   this.token = user.access_token;
    // }
    //
    // if (this.public) { // флаг для страниц, не требующих авторизации
    //   return;
    // }
    // if (this.isRenderer) {
    //   return;
    // }
    // if (!this.isPage) {
    //   return;
    // }
    //
    // // Получаем userID, token, storageUrl только в корневой сущности Vue
    // this.userId = localStorage.getItem('userId');
    //
    // if (this.who !== 'viewer' && this.who !== 'index') {
    //   if ((this.userId == null) && !this.loginPage) {
    //     this.gotoUrl('login');
    //     return;
    //   }
    //   if ((this.userId != null) && this.loginPage && localStorage.getItem('isLoggedIn') !== 'false') {
    //     localStorage.setItem('isLoggedIn', false);
    //     this.gotoUrl('account');
    //   }
    // }
  },
  methods: {
    getInstallerData() {
      let extension, url;
      const that = this;
      const ru = 'https://s3.amazonaws.com/release.slidelab.io/';
      // console.log that.os
      switch (that.os) {
        case 'Windows':
          url = `${ru}latest.yml`;
          that.installer.osName = 'Windows';
          extension = 'exe';
          break;
        case 'Mac':
          url = `${ru}latest-mac.yml`;
          that.installer.osName = 'Mac';
          extension = 'dmg';
      }
      $.ajax({
        type: 'GET',
        url,
        async: true,
        success(data) {
          let res;
          try {
            res = YAML.parse(data);
            res.files.forEach(function(f) {
              if (f.url.includes(extension)) {
                that.installer.url = `${ru}${f.url}`;
                that.installer.size = Math.round(f.size / 1024 / 1024);
              }
            });
          } catch (error) {
            // eslint-disable-next-line no-unused-vars
            console.log(error);
          }
        },
        error(data) {}
      });
    },
    requestToken() {
      const that = this;
      $.ajax({
        type: 'GET',
        // url: @base_url+'/token'
        url: this.base_url + '/api/v1/' + this.token,
        success(data) {
          // console.log data
          that.token = data;
        },
        error(data, textStatus, errorThrown) {}
      });
    },
    sendRequest(method, url, data, successCB, failCB, showLog, reqParams, progressCB) {
      let xsrfCookie;
      // console.log(@base_url)
      const req = (xsrfCookie) => {
        const reqParamsObj = {
          headers: {
            'X-XSRF-TOKEN': xsrfCookie,
            'Authorization': `Bearer ${this.token}`
          },
          type: method,
          url: this.base_url + '/api/v1/' + url,
          data,
          xhr() {
            const xhr = $.ajaxSettings.xhr();
            xhr.upload.onprogress = function(e) {
              let progress;
              if (typeof progressCB === 'function') {
                progress = Math.floor(e.loaded / e.total * 100);
                progressCB(progress);
              }
            };
            return xhr;
          },
          beforeSend: () => {
            this.currentPage.activeRequests.push(url);
          },
          success: (data) => {
            this.currentPage.activeRequests.splice(this.activeRequests.indexOf(url), 1);
            if (typeof data === 'string') {
              // console.log data
              data = JSON.parse(data);
            }
            // if showLog
            // console.log data
            if ((successCB != null) && typeof successCB === 'function') {
              successCB(data);
            }
          },
          error: (data) => {
            this.currentPage.activeRequests.splice(this.activeRequests.indexOf(url), 1);
            if (typeof data === 'string') {
              data = JSON.parse(data);
            }
            // if showLog
            // console.log data
            if ((failCB != null) && typeof failCB === 'function') {
              failCB(data);
            }
          }
        };
        if (reqParams) {
          Object.keys(reqParams).forEach(function(k) {
            // eslint-disable-next-line no-return-assign
            return reqParamsObj[k] = reqParams[k];
          });
        }
        // eslint-disable-next-line no-unused-vars
        const r = $.ajax(reqParamsObj);
      };
      xsrfCookie = document.cookie.match(/XSRF-TOKEN=([\w-]+)/);
      if (xsrfCookie != null) {
        xsrfCookie = decodeURIComponent(xsrfCookie[1]);
      }
      data.userId = this.currentPage.userId;
      // if showLog
      //   console.log method, url, data
      req(xsrfCookie);
    },
    uploadFile(url, data, successCB, failCB, showLog, progressCB) {
      let k, v;
      // if showLog
      //   console.log data
      data.userId = this.currentPage.userId;
      const formData = new FormData();
      for (k in data) {
        v = data[k];
        formData.append(k, v);
      }
      const token = UserSingleton.getToken();
      const reqParams = {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        processData: false,
        contentType: false,
        mimeType: "multipart/form-data"
      };
      this.sendRequest('POST', url, formData, successCB, failCB, showLog, reqParams, progressCB);
    },

    generateValidUrl(meaning, payload) {
      let url;
      url = '/';
      url += meaning;
      // ToDo: Супер странная хуйня))))
      // if (window.location.href.includes('localhost')) {
      //   url += '.html';
      // }
      if (payload) {
        url += payload;
      }
      return url;
    },
    gotoUrl(url, payload, newTab) {
      const validUrl = this.generateValidUrl(url, payload);

      if (newTab) {
        window.open(validUrl, '_blank').focus();
      } else {
        window.location = validUrl;
      }
    }
  }
};
